import React, {useState, useEffect, useRef} from 'react';

import '../Styles/Nav.scss'
import resume from '../Images/resume.pdf'
function Nav() {
    const [y, setY] = useState(window.scrollY);
    const [viewWidth, setViewWidth] = useState(window.innerWidth);

    const menu = useRef(null);
    const toggleIcon = useRef(null)
    const navbar = useRef(null)

    const handleToggleMenu = () => {
        const ul = menu.current;
        const toggle = toggleIcon.current;
        ul.classList.toggle('active');
        if(toggle.classList.length === 0) {
            toggle.classList.toggle('close')
        } else {
            toggle.classList.toggle('close')
            toggle.classList.toggle('open')
        }

    }

    useEffect(() => {
        function handleWidthChange() {
            setViewWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWidthChange);
        return () => {
            window.removeEventListener('resize', handleWidthChange);
        } 
    })

    window.onscroll = () => {
        if(viewWidth > 992) {
            const curScrollPos = window.scrollY;
            const nav = navbar.current;
            if(y > curScrollPos) {
                nav.style.top = "0px";
            } else {
                nav.style.top = "-50px";
            }
            setY(curScrollPos);
        }
        
    }

    return(
        <nav ref={navbar}>
            {/* <div className="nav-logo">
                <img src={logo} alt="Logo"></img>
            </div> */}
            <ul className="full-screen-social-links">
                <li>
                    <a href="https://github.com/cbonoan/" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="mailto:charlesbonoan@gmail.com" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/_chaaarles_" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>
                    </a>
                </li>
            </ul>
            <ul ref={menu} className="nav-links">
                <li><a onClick={handleToggleMenu} href="#home-page">HOME</a></li>
                <li><a onClick={handleToggleMenu} href="#about-container">ABOUT</a></li>
                <li><a onClick={handleToggleMenu} href="#skills-container">SKILLS</a></li>
                <li><a onClick={handleToggleMenu} href="#projects-container">PROJECTS</a></li>
                <li><a onClick={handleToggleMenu} href={resume} target="_blank" rel="noreferrer">RESUME</a></li>
                <li className="social-links">
                    <ul>
                        <li>
                            <a href="https://github.com/cbonoan/" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:charlesbonoan@gmail.com" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/_chaaarles_" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <span ref={toggleIcon} onClick={handleToggleMenu} className="">
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="nav-toggle-container">
                        <circle id="nav-circle" cx="22.5" cy="22.5" r="22.5" fill="white"/>
                        <g id="nav-toggle">
                            <path id="line-1" d="M11 29L33 29" stroke="black" stroke-width="2" stroke-linecap="round"/>
                            <line id="line-2" x1="15" y1="22" x2="32" y2="22" stroke="black" stroke-width="2" stroke-linecap="round"/>
                            <path id="line-3" d="M11 15L33 15" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </g>
                    </g>
                </svg>
            </span>
        </nav>
    )

}
export default Nav;